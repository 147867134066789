class AfterJS {
  static init() {
    // console.log("init afterjs");
  }
  static getJSScriptAfterSelectionChange() {
    return {
      name: 'jsAfterSelection',
      type: 'text',
      label: 'js calls after selection',
    }
  }

  static runAfterSelection(widget) {
    //AfterJS.init("run after selection ",widget);

    if (widget.props.jsAfterSelection != null) {
      let js = widget.props.jsAfterSelection
      console.log(js, widget)
      try {
        eval(js) //eslint-disable-line no-eval
      } catch (e) {
        console.log(e)
      }
    }
  }

  static fillParameterUsingSelectionURIAndPred(widget, parameter, pred) {
    let pv = widget.props.publishVariable
    let uri = widget.props.pubsub[pv]
    //if (uri==null) return;

    let sparql =
      'select distinct ?result where {optional {<' +
      uri +
      '><' +
      pred +
      '>?value} bind (IF(BOUND(?value),"niks","3d") as ?result)} limit 1'
    // console.log(sparql);
    window.top.MySparql.select(sparql, function (arg) {
      let result = arg.results.bindings[0].result.value
      //  console.log(result);
      widget.props.publish(parameter, result)
    })

    //console.log(widget,parameter,pred);
  }

  static test(widget) {
    console.log('testing Afterjs ')

    console.log(widget)
  }
}

export default AfterJS
