import { lazy } from 'react';
export default class BBKVKProductTemplateDescriptor {

  static getDescription() {
    return {
      name: 'BBKVKProductTemplate',
      component: lazy(() =>  import('./BBKVKProductTemplate')), 
      label: 'BBK KVK Product Template',
      variables: [
        {
          name: 'title',
          type: 'text',
          label: 'Titel boven de template',
        },
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the form. Use title,uri, propUri, label,value, type (xsd,select), optionLabel optionValue ?required regex, multiple,objectOfCls ',
        },
        {
          name: 'buttons',
          type: 'array',
          label: 'buttons',
        },
        {
          name: 'popupFullScreen',
          type: 'array',
          label: 'popupFullScreen',
        },
        {
          name: 'popupTitle',
          type: 'array',
          label: 'popupTitle',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label: 'Variabele in which URI is published when button is clicked.',
        },

        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
      ],
    }
  }

  
  
}
