import React from "react"
import Dialog from "./Dialog"
import styles from "./FormField.module.css"
import { Button as Button_ } from "reactstrap"
import { ShowableComponent } from "../hooks/use-dialog"
const Button = Button_ as any

export function HelpDialog(props: {
  component: ShowableComponent<{}, void>
  onClose: () => void
}) {
  const Component = props.component
  return (
    <Dialog onClose={props.onClose}>
      <div className={styles["help"]}>
        <Component onClose={props.onClose} />
      </div>

      <div style={{ textAlign: "center" }}>
        <Button color="primary"
                style={{ minWidth: "120px", marginTop: "12px" }}
                onClick={() => props.onClose()}>OK</Button>
      </div>
    </Dialog>
  )
}