import BBIFrame from './BBIFrame'
import PublishUtils from '../../../helpers/PublishUtils'

export default class BBSVG extends BBIFrame {
  getUrl = () => `/${window.bbFolderName}/w2ui/SVG.html`

  constructor() {
    super()

    this.scrolling = 'false'
    this.noEvents = false
  }

  static getDescription() {
    return {
      name: 'BBSGV',
      component: BBSVG,
      label: 'SVG widget (Single Line Diagram)',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the svg. use ?uri ?x ?x ?type ',
        },
              {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which an item URI is published, when a feature in the map is clicked.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },
          
       

        {
          name: 'dragNodes',
          type: 'boolean',
          label: 'drag nodes',
        },
      
        PublishUtils.getResetValueDef(),
        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
      ],
    }
  }



}
