if (Singleton == null) { //eslint-disable-line no-use-before-define
  var Singleton = (function () {
    var instance

    function createInstance() {
      var object = new Object('I am the instance 22') //eslint-disable-line no-new-object
      return object
    }

    return {
      getInstance: function () {
        if (!instance) {
          instance = createInstance()
        }
        //   console.log(instance);
        return instance
      },
    }
  })()
}

if (window.top.Singleton == null) {
  window.top.Singleton = Singleton
}
