import { z } from "zod"
import { ProjectSchema } from "./project"

export const UserSchema = z.object({
  id: z.string(),
  email: z.string(),
  name: z.string(),
  project: ProjectSchema.optional(),
  role: z.union([
    z.literal("superadmin"),
    z.literal("admin"),
    z.literal("editor"),
    z.literal("viewer")
  ])
})

export type User = z.infer<typeof UserSchema>
