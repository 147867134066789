import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './store'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import './tailwind.build.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from "@mui/material/styles"
import { theme } from "./components/mui/theme"


const queryClient = new QueryClient()

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </QueryClientProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
//serviceWorker.register();
