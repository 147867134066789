import { styled, Tooltip as MuiTooltip, tooltipClasses, TooltipProps } from "@mui/material"

export const LargeTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '0.875rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    padding: '.75em 1em'
  }
}))
