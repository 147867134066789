
import { lazy } from 'react';

export default class BBTableDescriptor{

  static getDescription() {
    return {
      name: 'BBTable',
      component: lazy(() =>  import('./BBTable')),
      label: 'BB Table widget',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the table use any parameter. Optionally use Check parameter extension for boolean values. Optionally use the Fix extension (?parameternameFix) to restrict the amount of numbers after the decimal for each parameter. Optionally use ?background for background coloring ',
        },
        {
          name: 'renderEmpty',
          type: 'boolean',
          label: 'render an empty table',
        },
        {
          name: 'colSizes',
          type: 'array',
          label: 'optional array of col sizes. 100px,50%,350px. Needs a widgetrefresh when changed',
        },

        {
          name: 'publishVariable',
          type: 'text',
          label: 'Published uri variabele for other widgets.',
        },

        {
          name: 'replaceHeaderJSON',
          type: 'text',
          label: 'Key value JSON that replaces parameter headers. {"label":"naam (volledig)"}',
        },
        {
          name: 'searchbar',
          type: 'boolean',
          label: 'show searchbar (widget refresh necessary)',
        },
        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
        {
          name: 'uselocalnumberformat',
          type: 'boolean',
          label: 'use local number format',
        },
        {
          name: 'downloadbutton',
          type: 'boolean',
          label: 'add toolbar with download button (widget refresh necessary)',
        },
        {
          name: 'downloadURL',
          type: 'text',
          label: 'base exceltemplate. This will be used to insert the data in the first sheet',
        },
        {
          name: 'downloadStartRow',
          type: 'text',
          label: 'start row to insert the data into the excelsheet',
        },
        {
          name: 'downloadNoHeader',
          type: 'boolean',
          label: 'do not export sparql parameter names as columnames',
        },
        {
          name: 'wraptext',
          type: 'boolean',
          label: 'wrap text in the table',
        },
        {
          name: 'excelheadertext',
          type: 'text',
          label: 'headertext for row 1 when downloading this table in excel',
        },

        {
          name: 'summaryFields',
          type: 'text',
          label: 'add summary below using the following csv sparql parameters (costs, profit)',
        },
        {
          name: 'filename',
          type: 'text',
          label: 'xlsx filename',
        },
      ],
    }
  }
}

