import React, { Component } from 'react'
import Table from '../../Table'

class BBKVKFlexibleTextWidget extends Component {
  static getDescription() {
    return {
      name: 'BBKVKFlexibleTextWidget',
      component: BBKVKFlexibleTextWidget,
      label: 'BBKVKFlexibleTextWidget widget',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'Query to fill table. If table item should be a link, than the you should add a property with the same name with suffix "URI", eg: ?object ?objectURI.',
        },
      ],
    }
  }

  componentDidMount2(arg, arg2) {
    console.log(this.props, arg, arg2)
  }
  shouldComponentUpdate2(arg) {
    console.log(arg)
    return true
  }
  openLink = (url) => {
    window.open(url, '_blank')
  }

  analyseResults(results) {
    var rc = {}
    if (results == null) {
      return rc
    }
    for (var n in results.bindings) {
      var binding = results.bindings[n]
      // console.log(binding,results);
      if (binding.page != null) {
        rc.page = binding.page.value
      }
    }

    return rc
  }

  render() {
    if (!this.props.data || this.props.data.results.bindings.length === 0) {
      return null
    }
    var rc = this.analyseResults(this.props.data.results)
    if (rc.page != null) {
      return (
        <div>
          <p>
            <a target="_blank" href={rc.page}> {/*eslint-disable-line react/jsx-no-target-blank*/}
              open wet in nieuwe tab
            </a>
            <Table {...this.props}></Table>
          </p>
        </div>
      )
    }
    return <Table {...this.props}></Table>
  }
}

export default BBKVKFlexibleTextWidget
