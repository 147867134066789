import Command from "./Command"
import { resolveTemplateStringAndLogErrors } from "./resolve-template-string"
import { store } from "../store"
import { isValidUri } from "../utils/uri"

export function runStartRule(ruleTagOrUriTemplate: string, parametersJson: string | null | undefined, additionalTemplateVariables?: Record<string, string>) {
  const dict = {
    ...(store.getState()?.pubsub ?? {}),
    ...(additionalTemplateVariables ?? {}),
     
  }
    //console.log(dict)
   return new Promise((resolve, reject) => {
   
    const resolvedRule = resolveTemplateStringAndLogErrors(ruleTagOrUriTemplate, dict)
    const resolvedParameters = resolveTemplateStringAndLogErrors(parametersJson ?? "", dict)
   //console.log("promise",resolvedParameters,dict);
  
    

    if (isValidUri(resolvedRule)) {
      Command.ruleRule(resolvedRule, resolvedParameters, resolve, reject)
    } else {
      Command.runRulesWithTag(resolvedRule, resolvedParameters, resolve, reject)
    }
  })
}
