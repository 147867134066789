import axios from 'axios'
import { getAbsoluteRepoUrl } from './url'

export function not(fn) {
  return (...args) => !fn(...args)
}

export function isEmptyObject(obj) {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  )

}
const CancelToken = axios.CancelToken

export function getSparqlFunction(props)
{
  return createSparqlFunction2(props);
}

/*
export function loadExternalJS(js)
{
    
}
*/

function getUrl(props)
{
  var ss=getAbsoluteRepoUrl(props.repoURL);
 // console.log(ss);
  return ss;
} 


function getUrl_old(props) {
  
  try {
    let url = '/api/sparql/' + props.repoURL
    if (props.repoURL.startsWith('http')) {
      // request sparql endpoint directly
      url = props.repoURL
    }
    var modus = false
    if (window.location.host === 'localhost:3000') {
      modus = true
    }
    //  var modus=false; //debug

    if (
      props.localRepoURL != null &&
      props.localRepoURL !== '' &&
      modus
    ) {
      url = props.localRepoURL
    }
   
  //  console.log('returning url :' + url)
    return url
  } catch (e) {
    console.log('error creating url' + e)
  }
  return null
}

function createSparqlFunction2(props) {
  // gebruikt door bb widgets in iframe situation
  var url =getUrl(props)
 var f = function (query, success, errorRV) {
    // console.log("query via window top ",query,success,errorRV);

    let encodedQuery = encodeURIComponent(query)
    let data = {
      query: query,
    }
    data = 'infer=false&sameAs=false&query=' + encodedQuery

    axios.defaults.headers.post['Content-Type'] =
      'application/x-www-form-urlencoded'
    //axios.defaults.headers.post['Access-Control-Allow-Origin'] ='*';
    axios.defaults.headers.post['Accept']='application/sparql-results+json,*/*;q=0.9'
    //console.log("querying with ",query,headers);
    axios.defaults.withCredentials = true
    axios.defaults.credentials = 'include'
    // console.log("really sparqling ",query);
     if (document.withCredentials==false)
     {
      axios.defaults.withCredentials = false
     }
    axios({
      method: 'post',
      url,
      data,
     
      cancelToken: new CancelToken((c) => {
        this.cancel = c
      }),
    })
      .then((response) => {
        if (success) {
          success.call(this, response.data)
        }
      })
      .catch((error) => {
        //  console.log(error,query);
        if (errorRV) {
          errorRV.call(this, error)
        }
      })
  }

 return f;
}
