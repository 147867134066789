import styles from './ToggleSwitch.module.css'

export const ToggleSwitch = (props: {
  isToggled: boolean
  onClick: () => void
}) => {
    return (
      <label className={styles["toggle-switch"]}>
        <input type="checkbox" checked={props.isToggled} onClick={() => props.onClick()} />
        <span className={styles["switch"]} />
      </label>
    );
  };
  