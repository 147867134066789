import { lazy } from 'react';

export const excelWidgetDescription = {
    name: 'Excel widget',
    component:lazy(() => import('./ExcelWidget' )),
    label: 'Excel widget',
    variables: [
      {
        name: 'excelUrl',
        type: 'text',
        label:
          'An excel URL to load (If there is a excelUrlTopic specified, this will be ignored)',
      },
      {
        name: 'excelUrlTopic',
        type: 'text',
        label:
          'Published topic on which to listen to the excel URL to load',
      },
      {
        name: 'selectedCellLocation',
        type: 'text',
        label:
          'A variable to publish the selected cell location. The publish format is excel axis e.g "A10", "B8".',
      },
      {
        name: 'selectedCellValue',
        type: 'text',
        label:
          'A variable to publish the selected cell value',
      },
      {
        name: 'currentActiveSheetName',
        type: 'text',
        label:
          'A variable to publish the name of the current active sheet',
      },
      {
        name: 'highlightColor',
        type: 'text',
        label: 'The default color for hightlighting the cells in format of Hex Code (#RRGGBB). If no highlighting color is specified in the sparql data, this color will be used.',
      },
      {
        name: 'fastHighlighting',
        type: 'boolean',
        label: '[experimental] Enable this to highlight the cells is faster. But resetting the hightlights may requires reloading workbook or refresh the page. Note: Only the default color can be used for this highlight.',
      },
      {
        name: "query",
        type: 'yasgui',
        label:
          'Query certain cells to be highlighted in the loaded workbook. The query pattern should be `select ?sheetIndex ?sheetName ?row ?col ?color ?value ?range` Note: Only cells that has values and their values are equal to ?value can be highlighted! If both sheetIndex and sheetName are specified, sheetIndex takes priority.',
      },
    ],
  }