import './bbutil'
import BBIFrame from './BBIFrame'
import PublishUtils from '../../../helpers/PublishUtils'

class BBIFCViewer extends BBIFrame {
  getUrl = () => `/${window.bbFolderName}/static/iframes/ifcviewer/indexFragments.html`

  constructor() {
    super()

    this.scrolling = 'no'
    this.noEvents = false

    //this.updateMe=this.updateMe2; // no throttle
    //console.log("noThrottle");

    
  }

  static getDescription() {
    return {
      name: 'BBIFCViewer',
      component: BBIFCViewer,
      label: 'BBIFCViewer',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            ' Use ?uri ?expressID ?file. optionally ?highlight boolean . Optionally use ?color for automatic coloring.',
        },

        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which an item URI is published, when a feature in the map is clicked.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },
        PublishUtils.getResetValueDef(),
        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
        {
          name: 'resetparameter',
          type: 'text',
          label: 'resets the viewer when this parameter changes (BETA)',
        },
        {
          name: 'reloadparameter',
          type: 'text',
          label: 'reloads the IFCviewer when this parameter changes for more consistent viewer behaviour',
        },
        {
          name: 'selectguidquery',
          type: 'yasgui',
          label:
            ' Use ?guid to highlight (make red) objects in the 3d viewer',
        },
      ],
    }
  }

  state = { selectedUri: null }

  shouldComponentUpdate(nprops, nstate) {
    let b = super.shouldComponentUpdate(nprops, nstate)
    try {
      let par = nprops['resetparameter']
      if (par != null) {
        let par1 = this.props.pubsub[par]
        let npar1 = nprops.pubsub[par]

        if (par1 !== npar1) {
          if (this.iface.resetViewer != null) {
            this.iface.resetViewer()
          }
        }
      }
    } catch (e) {}
    try {
      let par = nprops['reloadparameter']
      if (par != null) {
        let par1 = this.props.pubsub[par]
        let npar1 = nprops.pubsub[par]

        if (par1 !== npar1) {
          console.log("reload BBIFCViewer");
          this.reload();
          return true
        }
      }
    } catch (e) {}

    return b
  }
}

export default BBIFCViewer
