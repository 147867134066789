export default class WidgetCategory
 {
     static  basic="basic";
     static  experimental="experimental";
     static  widget2D="2D";
     static  widget3D="3D";
     static  graphic="graphic";
     static  edit="edit";
     static  other="other";
     static  layout="layout";
     
}