import React, { Component } from 'react'
import { Tooltip, IconButton } from '@mui/material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'

import Tab from '../Tab'

export default class BBBackMenu extends Component {
  state = { anchorEl: false, selections: [] }

  static getDescription() {
    return {
      name: 'BBBackMenu',
      component: BBBackMenu,
      label: 'BackMenu',
      variables: [
        {
          name: 'parameter',
          type: 'text',
          label: 'parameter ',
        },
        {
          name: 'changeSelectedTabs',
          type: 'boolean',
          label: 'also set tab selections ',
        },
      ],
    }
  }

  shouldComponentUpdate(props, state) {
    try {
      var newValue = props.pubsub[props.parameter]
      if (newValue == null) return true

      if (this.state.selections.length === 0) {
        var o = { parameter: newValue, tabs: {} }
        for (var n in Tab.registeredTabComponents) {
          let t = Tab.registeredTabComponents[n]
          o.tabs[t.getTabId()] = t.state.selectedIndex
        }
        this.state.selections.push(o)
      } else {
        var lastValue =
          this.state.selections[this.state.selections.length - 1].parameter
        if (lastValue !== newValue) {
          o = { parameter: newValue, tabs: {} }

          for (const n in Tab.registeredTabComponents) {
            let t = Tab.registeredTabComponents[n]
            o.tabs[t.getTabId()] = t.state.selectedIndex
          }
          this.state.selections.push(o)
        }
      }
      // console.log(this.state.selections);
    } catch (e) {
      console.log(e)
    }
    //console.log(par,par2);
    return true
  }

  handleClickBack = (event) => {
    //this.setState({anchorEl:event.currentTarget});

    if (this.state.selections.length === 0) return
    if (this.state.selections.length === 1) return
    // min 2 dus kan terug
    var rValue = this.state.selections[this.state.selections.length - 2]

    if (this.props.parameter != null) {
      this.props.publish(this.props.parameter, rValue.parameter)
      this.state.selections.pop()
      this.state.selections.pop()
    }
    //this.setState({anchorEl:null});
    if (this.props.changeSelectedTabs) {
      for (var n in Tab.registeredTabComponents) {
        var t = Tab.registeredTabComponents[n]
        for (var tabId in rValue.tabs) {
          if (tabId === t.getTabId()) {
            var sel = rValue.tabs[tabId]
            if (t.state.selectedIndex !== sel) {
              //console.log("found a tab that needs to be switched",t);
              t.setState({ selectedIndex: sel })
            }
          }
        }
      }
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    var disabled = false
    if (this.state.selections.length < 2) {
      disabled = true
    }
    //var sel = this.state.selections;
    return (
      <div>
        <Tooltip title="back">
          <span>
            {' '}
            <IconButton
              disabled={disabled}
              variant="contained"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={this.handleClickBack}
            >
              <ChevronLeft />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    )
    /*
      
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={this.handleClose}
      >
        {sel.map(index =>(
        <MenuItem onClick={this.handleClose}>hallo uri:{sel[index]}</MenuItem>
        
        ))
      }
      </Menu>
      
        )
        */
  }
}
