import './bbutil'
import BBIFrame from './BBIFrame'
import PublishUtils from '../../../helpers/PublishUtils'

class BBForm extends BBIFrame {
  getUrl = () => `/${window.bbFolderName}/w2ui/form.html`

  constructor() {
    super()
    this.scrolling = 'true'
    this.noEvents = false
  }
  static getDescription() {
    return {
      name: 'BBForm',
      component: BBForm,
      label: 'BB Form widget',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the map. Use ?uri and ?geometry for WKT strings. Optionally use ?color for automatic coloring ',
        },
        {
          name: 'fields',
          type: 'json',
          label: 'json for w2uiform fields',
        },

        {
          name: 'tabs',
          type: 'text',
          label: 'w2ui tabs definitie',
        },
        {
          name: 'filetype',
          type: 'text',
          label: 'file type extension (e.g.:ifc) ',
        },
        {
          name: 'service',
          type: 'select',
          label: 'service upload or command',
          options: [
            {
              label: 'command',
              value: 'command',
            },
            {
              label: 'upload',
              value: 'upload',
            },
          ],
        },
       
        {
          name: 'commando',
          type: 'text',
          label: 'commando',
        },
        {
          name: 'dataCommand',
          type: 'text',
          label: 'extra data send to the server via json',
        },
        {
          name: 'endJS',
          type: 'text',
          label:
            'js after successfull submit   (getIface().me.props.closeDialog())',
        },
        PublishUtils.getTimestampParametersDef(),
      ],
    }
  }
}

export default BBForm
