import axios from 'axios'
import LoginHelper from './LoginHelper'
import { parseTextForVariables } from '../helpers/sparql'
import { getAbsoluteRepoUrl } from '../helpers/url'


class Command {



  constructor(widget)
  {
    this.widget=widget;

  }
  
  static getRepoUrl()
  {
    //axios.defaults.timeout = 120000;
    var url = window.top.repoURL;
    if (url!=null) return url;

    var iframe = document.querySelector('iframe'); // Selects the first iframe in the document

    if (iframe) {
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
       return iframeDocument.repoURL;
      
    }

    return null;
}



 static  test()
  {
    Command.sendCommand2({commando:"stroefheidcurve",uri:"http://www.rws.nl/VenR/2023/nwb/wegvakken#RWRW016B1HRRv36p1t36p2"});
  }

  uploadTest()
   {
    //console.log("upload test");

    const uploadButton = document.createElement('button');
    uploadButton.id = 'uploadButton';
    uploadButton.innerText = 'Upload File';
    document.body.appendChild(uploadButton);

    // Create and add the hidden file input dynamically
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.id = 'fileInput';
    fileInput.style.display = 'none';
    document.body.appendChild(fileInput);
    uploadButton.addEventListener('click', function() {

      fileInput.click();
    });
    var me=this;
    // Add event listener to the file input
    fileInput.addEventListener('change', function(event) {
      const files = event.target.files;
      me.uploadFile(files,{uri:"http://wistor.nl/zelfverzonnenURI",belongsTo:"http://wistor.nl/zelfverzonnenParent"});
    });
    



   }

   uploadFile(files,extra)
   {
    const formData = new FormData()

  
    if (extra!=null)
    {
      try
      {
            
             for (var key in extra)
             {
            
              var l=parseTextForVariables(extra[key],this.props);
              
             // console.log(key,extra[key],l);
              formData.append(key,l);
             }
      }
      catch(e){console.log(e);}
    }



    var me = this
    if (!files.length) return
    const file = files[0]


    formData.append('command', "uploadAnyFile")
    formData.append('file', file, file.name)
    
    axios.defaults.withCredentials = true
    axios.defaults.credentials = 'include'
    
    let url = getAbsoluteRepoUrl(this.widget.props.repoURL);
    if (url == null) {
      alert('no upload url defined')
      return
    }
    
      url=url.replace("sparql","upload");
     
    //console.log(this.props,url);
    var promise=axios
      .post(url, formData)
      .then(function (arg) {
       // console.log('SUCCESS upload ',arg);
        
       
      })
      .catch(function (e) {
        console.log('upload failure ',e);
        alert('could not upload file')
        me.setState({ enabled: true })
      })


   return promise
   }


  
  static sendCommand(component, command, options, rv,failure) {
    if (options == null) options = {}

   
    //let repoURL=window.cgi+"sparql";
    
    let repoURL=null;
    try {     repoURL=component.props.repoURL; } catch(e){}
   
    if (repoURL != null && repoURL.includes('sparql')) {
      let commandUrl = repoURL.replace('sparql', 'command')
      const formData = new FormData()
      formData.append('commando', command)
      options.commando = command
      if (options != null) {
        for (let key in options) {
          formData.append(key, options[key])
        }
      }
      axios.defaults.withCredentials = true
      axios.defaults.credentials = 'include'

     
        axios.post(commandUrl, options, {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Content-Type': 'application/json',
            },
          })
          .then(function (arg) {
            console.log('SUCCESS command', arg)
            if (rv != null) {
              rv.call(this, arg)
            }
          }).catch (function(e) {
       // console.log('command error ', e);
        if (failure!=null)
        {
          failure.call(this);
        }
        else
        {
        alert('command error');
        }
      })
    }
  }

  static sendCommand2(options,success,failure) 
  {
     //for iframe calls.
     //let repoURL=window.cgi+"sparql";
     let repoURL=Command.getRepoUrl();

    if (repoURL != null && repoURL.includes('sparql')) {
      let commandUrl = repoURL.replace('sparql', 'command')
      const formData = new FormData()
      if (options != null) {
        for (let key in options) {
          formData.append(key, options[key])
        }
      }
      axios.defaults.withCredentials = true
      axios.defaults.credentials = 'include'
      //extr timeout here?
      try {
        axios
          .post(commandUrl, options, {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Content-Type': 'application/json',
            },
          })
          .then(function (arg) {
         
            if (arg!=null)
            {
              console.log("\n\nSuccesful RULE results:\n");
               console.log(arg.data);
              if (arg.data!=null)
              {
               
                 try {
                 // console.log('SUCCESS 2 command',arg.data);
                    if ((arg.data.success!=null)  && (arg.data.success!=false) )
                    {
                      if (success!=null){success.call(this,arg);}
                      return;
                    }

                 }
                 catch(e)
                 {
                  console.log("error",e);
                 }
              }
              try 
              {
                    console.log(arg.data.errors);
              }
              catch(ex)
              {}
            }
            if (failure!=null){failure.call(this);}
          
           
          })
      } catch (e) {
        console.log('command error', e)
        if (failure!=null){failure.call(this,e);}
      }
    }
  }

  static ruleRule(uri,parameters,success,failure)
   {
    var json = {
      commando: 'VRCommands',
      command2: 'runSparqlRule',
      command3: uri,
      parameters:parameters
    }
    Command.sendCommand2(json,success,failure)
  }




  


  static runRulesWithTag(tag,parameters,success,failure) {
   // console.log('run rules met specifieke tag ' ,tag,parameters)
    var json = {
      commando: 'VRCommands',
      command2: 'runSparqlRulesWithTag',
      command3: tag,
      parameters:parameters,
      //test:"Hans was here"
    }

    Command.sendCommand2(json,success,failure)
  }

  static deleteServerCache(graph)
  {
    var json = {
      commando: 'refreshCache',
      graph: graph,
    }
    Command.sendCommand2(json,function(arg){console.log("success",arg)},function(arg){console.log("error ",arg)})


  }

  static logoff() {
    let repoURL=window.cgi+"sparql";
    LoginHelper.logoff(repoURL);
  }

}

window.top.command = Command

export default Command
