import './bbutil'
import BBIFrame from './BBIFrame'

class BBYasgui extends BBIFrame {
  getUrl = () => `/${window.bbFolderName}/static/iframes/yasgui/yasguiConf.html`

  constructor() {
    super()

    this.scrolling = 'yes'
    this.noEvents = false
    this.usesParameters=false;
  }

  static getDescription() {
    return {
      name: 'BBYasgui',
      component: BBYasgui,
      label: 'BB YasGUI',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            '?sparql ?options variabel (single). getOptions() is available in yasgui ',
        },
        {
          name: 'pusblishsparqlquery',
          type: 'string',
          label:
            'publish the query into this parameter (single tab only)(refresh necessary)',
        },
        {
          name: 'escapeDoubleQuotesQuery',
          type: 'boolean',
          label: 'escape double quotes in the query',
        },
        {
          name: 'queryOnly',
          type: 'boolean',
          label: 'show only yasque ',
        },
      ],
    }
  }
}

export default BBYasgui
