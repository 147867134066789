import axios, { AxiosError } from "axios"
import { User, UserSchema } from "../model/user"

const LS_TOKEN_KEY = 'kvk-vb-token'

export type LoginCredentials = {
  email: string
  password: string
}

type LoginSuccess = {
  success: true
  user: User
}

type LoginFailure = {
  success: false
  reason: string
}

export async function login(credentials: LoginCredentials): Promise<LoginSuccess | LoginFailure> {
  try {
    const response = await axios.post('/api/login', credentials)
    const token = response.data.token
    localStorage.setItem(LS_TOKEN_KEY, token)

    const user = UserSchema.parse(response.data.me)
    return { success: true, user }
  } catch (error) {
    localStorage.removeItem(LS_TOKEN_KEY)

    if (!(error instanceof AxiosError)) {
      throw error
    }

    if (typeof error.response?.data !== 'string') {
      console.error(error)
      return { success: false, reason: 'Request failure' }
    }

    return { success: false, reason: error.response?.data }
  }
}

export async function logout() {
  localStorage.removeItem(LS_TOKEN_KEY)
}

const ERR_INVALID_RESPONSE_WITH_SUGGESTION =
  'Invalid response! When developing use `yarn dev`.'

  export  function getCurrentToken():string
  {
   
    const token = localStorage.getItem(LS_TOKEN_KEY)
  if (token == null) return "no token";
  return  `Bearer ${token}`;

  }

export async function getCurrentUser(): Promise<User | null> {
  const token = localStorage.getItem(LS_TOKEN_KEY)
  if (token == null) return null

  try {
    const response = await axios.get('/api/me', {
      headers: {
        Authorization: `Bearer ${token}`,
        withCredentials: true
      }
    })
    if (!response.data.me) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error(ERR_INVALID_RESPONSE_WITH_SUGGESTION)
    }
      
    return UserSchema.parse(response.data.me)
  } catch (error) {
    localStorage.removeItem(LS_TOKEN_KEY)

    if (!(error instanceof AxiosError)) {
      throw error
    }

    console.error(error)
    return null
  }
}
