import { lazy } from 'react';
export default class NebulaDescriptor  {

static getDescription() {
    return {
      name: 'Nebula',
      component: lazy(() =>  import('./Nebula')),
      label: 'Nebula',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'use parameter ?label for accordion headings. use ?content for inner accordion content ',
        },

        {
          name: 'title',
          type: 'text',
          label: 'Titel boven de tabel',
        },

       
      ],
    }
  }
}
