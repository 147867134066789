import { User } from "../model/user"

type LoggedInState = {
  user: User
  isLoggedIn: true
  isLoggingIn: false
}

type NotLoggedInState = {
  user: null
  isLoggedIn: false
  isLoggingIn: boolean
}

export type LoginState = LoggedInState | NotLoggedInState

const initialState: LoginState = {
  user: null,
  isLoggedIn: false,
  isLoggingIn: false
}

type LoginRequestAction = { type: "LOGIN_REQUEST" }
type LoginSuccessAction = { type: "LOGIN_SUCCESS", payload: User }
type LoginFailureAction = { type: "LOGIN_FAILURE" }
type LogoutAction = { type: "LOGOUT" }

export type LoginAction
  = LoginRequestAction
  | LoginSuccessAction
  | LoginFailureAction
  | LogoutAction

export default function loginReducer(state: LoginState = initialState, action: LoginAction): LoginState {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return { ...state, user: null, isLoggingIn: true, isLoggedIn: false }
    case "LOGIN_SUCCESS":
      return { ...state, user: action.payload, isLoggingIn: false, isLoggedIn: true }
    case "LOGIN_FAILURE":
      return { ...state, user: null, isLoggedIn: false, isLoggingIn: false }
    case "LOGOUT":
      return { ...state, user: null, isLoggedIn: false, isLoggingIn: false }
  }

  return state
}
