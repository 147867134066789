import React, { Component } from 'react'
import styles from './BBPicture.module.css'

export default class BBPicture extends Component {
  state = {}

  static getDescription() {
    return {
      name: 'Picture',
      component: BBPicture,
      label: 'Picture',
      variables: [
        {
          name: 'title',
          type: 'text',
          label: 'Titel boven het plaatje',
        },
        {
          name: 'pictureBaseEncoded',
          type: 'text',
          label:
            'base encoded picture. converteer plaatjes via bijv https://www.base64-image.de/',
        },
        {
          name: 'src',
          type: 'text',
          label: 'referentie naar het plaatje',
        },
      ],
    }
  }

  render() {
    let encodedData = this.props.pictureBaseEncoded

    if (encodedData) {
      return (
        <div className={styles.main}>
          {this.props.title && (
            <div className={styles.title}>{this.props.title}</div>
          )}
          <img src={encodedData} />{/*eslint-disable-line jsx-a11y/alt-text*/}
        </div>
      )
    }

    let src = this.props.src
    if (src) {
      return (
        <div className={styles.main}>
          {this.props.title && (
            <div className={styles.title}>{this.props.title}</div>
          )}
          <img src={src} />{/*eslint-disable-line jsx-a11y/alt-text*/}
        </div>
      )
    }
    return (
      <div className={styles.main}>
        {this.props.title && (
          <div className={styles.title}>{this.props.title}</div>
        )}
      </div>
    )
  }
}
