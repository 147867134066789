const CURRENT_KEY_PRESSES = new Set()
const userInputChannel = new BroadcastChannel('user-input-channel');

export function setupUserInputMessenger(windowElement: typeof window = window) {
  windowElement.addEventListener('keydown', (event) => {
    registerKey(event.key)
  })

  windowElement.addEventListener('keyup', (event) => {
    deregisterKey(event.key)
  })
}

function registerKey(key: string) {
  if (CURRENT_KEY_PRESSES.has(key)) return

  CURRENT_KEY_PRESSES.add(key)
  broadcastKeyRegistration()
}

function deregisterKey(key: string) {
  if (!CURRENT_KEY_PRESSES.has(key)) return

  CURRENT_KEY_PRESSES.delete(key)
  broadcastKeyRegistration()
}

function broadcastKeyRegistration() {
  userInputChannel.postMessage(CURRENT_KEY_PRESSES)
}
