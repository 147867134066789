import './bbutil'
import BBIFrame from './BBIFrame'
import PublishUtils from '../../../helpers/PublishUtils'
import AfterJS from '../../../helpers/AfterJS'
import _ from 'lodash';

class BBMultiMap extends BBIFrame {
  getUrl = () => `/${window.bbFolderName}/multimap/leafletWidgetDeploy171V1.html`

  constructor() 
  {
    super()
    //console.log("BBMap Constructor");
    this.scrolling = 'no'
    this.noEvents = false
    this.usesParameters=false;
  }

  static getDescription() {
    return {
      name: 'BBMultiMap',
      component: BBMultiMap,
      label: 'Leaflet Multi map',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the multimap. Use ?uri ?label ?graph ?colorPred ?colorPredLabel ?labelPred ?labelPredLabel ?selectedColorPred ?selectedLabelPred ?extra for sparql definitions',
        },
        {
          name: 'useSparqlMapDefinitions',
          type: 'boolean',
          label: 'use the sparql results for multiple map definitions',
        },
        
        {
          name: 'height',
          type: 'text',
          label: 'height variable',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variable in which an item URI is published, when a feature in the map is clicked.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variable on which to listen to define selection.',
        },
        PublishUtils.getMultipleValueFormatOptions({
          name: 'multiSubscribeParameterType',
          label: 'For the variable above, in what format the value is provided.'
        }),
        {
          name: 'multiSelectionPublishVariable',
          type: 'text',
          label:
            'Variable in which an item URIs are published, when multiple features on the map are selected.',
        },
        PublishUtils.getMultipleValueFormatOptions({
          name: 'multiSelectionParameterType',
          label: 'For the variable above, how to set the multivalue parameters'
        }),
        {
          name: 'multiSelectionShapePublishVariable',
          type: 'text',
          label:
            'Variable in which the drawn shape for multiple feature selection is published.',
        },
        {
          name: 'multiSelectionShapePublishFormat',
          type: 'select',
          options: [
            { label: 'GeoJson', value: 'GeoJSON' },
            { label: 'WKT (Well Known Text)', value: 'WKT' }
          ],
          label:
            'For the variable above, in which format to publish the geometry',
        },
        {
          name: 'multiSelectionType',
          type: 'select',
          options: [
            { label: 'hide drawing tools', value: 'hide' },
            { label: 'Intersect (features inside and touching the shape)', value: 'intersect' },
            { label: 'Within (only features inside the shape)', value: 'within' }
          ],
          label:
            'The boolean operation to use for selection by shape',
        },
        {
          name: 'redrawMapOnSelectionChange',
          type: 'boolean',
          label: 'redraw map on selection change. This can affect performance, but is needed to reflect changes visually for multi-select'
        },
        {
          name: 'advancedEdit',
          type: 'boolean',
          label: 'add advanced edit menubar (requires refresh)'
        },
        {
          name: 'loadShapeControl',
          type: 'boolean',
          label: 'add load shape as a zip file button (requires refresh)'
        },
        {
          name: 'measurementB',
          type: 'boolean',
          label: 'add measurement button (requires refresh)'
        },
        {
          name: 'fullscreenB',
          type: 'boolean',
          label: 'add fullscreen button (requires refresh)'
        },
        {
          name: 'downloadGeoJSON',
          type: 'boolean',
          label: 'download geojson file (requires refresh)'
        },
        {
          name: 'geocoderB',
          type: 'boolean',
          label: 'add geocoder button (requires refresh)'
        },
        {
          name: 'removeLayerwidgetB',
          type: 'boolean',
          label: 'remove layerwidget button (requires refresh)'
        },
        {
          name: 'removePrintB',
          type: 'boolean',
          label: 'remove print button (requires refresh)'
        },
        {
          name: 'zoomHomeB',
          type: 'boolean',
          label: 'remove zoom home buttons (requires refresh)'
        },

        
        {
          name: 'streetview',
          type: 'boolean',
          label: 'add streetview buttons'
        },

        

        {
          name: 'noLegend',
          type: 'boolean',
          label:   'hide legend  (reload is necessary for this to take effect)'
        },


        
        {
          name: 'subscribeExtraSparql',
          type: 'text',
          label:
            'parameter containing extra sparql ',
        },
        {
          name: 'selectboundingbox',
          type: 'boolean',
          label:'use a boundingbox for selecting an object',
        },
        {
          name: 'fitbounds',
          type: 'boolean',
          label:'after a data update modify the view to fit  ',
        },
       
        {
          name: 'allQueries',
          type: 'text',
          label:'parametername containing an array of all queries (not yet implemented, useful for downloadGIS function)',
        },
        {
          name: 'lineWidthScale',
          type: 'text',
          label:'lineWidthScale',
        },
        {
          name: 'lineWidthMinPixels',
          type: 'text',
          label:'lineWidthMinPixels',
        },
        

        AfterJS.getJSScriptAfterSelectionChange(),
        PublishUtils.getResetValueDef(),
        
      ],
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState)
    const subscribeVariable = this.props.pubsub[this.props['subscribeVariable']]
    if (subscribeVariable !== this.state.subscribeVariable) {
      try {
        this.setState({ subscribeVariable })
        const newSelectedUris = PublishUtils.parseMultiplePropValue(this.props['multiSubscribeParameterType'], subscribeVariable)
        this.sendSelectedUrisToIframe(newSelectedUris)
        this.setSelectedUris(newSelectedUris)
      } catch(e) {
        console.error([
          `An error occurred whilst using subscribeVariable "${this.props['subscribeVariable']}" to update the selected uris. `,
          `Perhaps multiSubscribeParameterType "${this.props['multiSubscribeParameterType']}" needs a different setting. `,
          `Additional details:`,
          '\n\n',
          e.message].join('')
          )
      }
    }
  }

  setSelectedUris = (selectedUris = []) => {
    this.setState({ selectedUris })
    if (this.props['publishVariable']!=null)
    {
      this.props.publish(this.props['publishVariable'], selectedUris[0])
    }
    if (  this.props['multiSelectionPublishVariable']!=null)
    {
      this.props.publish(
        this.props['multiSelectionPublishVariable'],
        PublishUtils.getListAsSingleValue(this.props['multiSelectionParameterType'], selectedUris)
      )
    }
  }

  sendSelectedUrisToIframe = (selectedUris = []) => {
    this.iframeRef.current?.contentWindow?.postMessage?.({ type: 'update-selected-uris', selectedUris })
  }

  state = { selectedUris: [], subscribeVariable: undefined }
}

export default BBMultiMap
