import axios from 'axios'

export const fetchUsers = (cb) => async (dispatch, getState) => {
  if (getState().users.loaded) return
  dispatch({ type: 'FETCH_USERS_REQUEST' })
  try {
    let { data } = await axios({
      method: 'get',
      url: '/api/users',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('kvk-vb-token')}`,
      },
    })
    dispatch({ type: 'FETCH_USERS_SUCCESS', data })
  } catch (error) {
    console.log(error)
    cb(error)
  }
}

export const createUser = (values, cb) => async (dispatch, getState) => {
  try {
    let { data } = await axios({
      method: 'post',
      url: '/api/users',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('kvk-vb-token')}`,
      },
      data: values,
    })
    dispatch({ type: 'NEW_USER_SUCCESS', data })
  } catch (error) {
    console.log(error)
    cb(error)
  }
}

const initialState = {
  loaded: false,
  loading: false,
  items: [],
}

export default function handleUser(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_USERS_REQUEST':
      return {
        ...state,
        loading: true,
      }
    case 'FETCH_USERS_SUCCESS':
      return {
        ...state,
        items: action.data,
        loading: false,
        loaded: true,
      }
    case 'NEW_USER_SUCCESS':
      return {
        ...state,
        items: [...state.items, action.data],
      }
    default:
      return state
  }
}
