

import { lazy } from 'react';

export default class SplitLayoutTestDescriptor  {
  static getDescription() {
    return {
      name: 'SplitLayoutTest',
      component: lazy(() =>  import('./SplitLayoutTest')), 
      label: 'Split Layout Test',
      variables: [
        {
          name: 'direction',
          type: 'select',
          label: 'Direction',
          options: [
            {
              label: 'Rijen',
              value: 'column',
            },
            {
              label: 'Kolommen',
              value: 'row',
            },
          ],
        },
        {
          name: 'areaSizes',
          type: 'array',
          // label: 'Column widths or row heights (separated by commas). Value can be a number (which will be set as flex values/verhoudingswaarden) or a px/% value',
          label:
            "Kolom Breedte of Rij hoogte, gescheiden door comma's. Waarden kunnen gegeven worden in getallen. Een getal is verhoudingsgetal ten opzichte van de andere Rijen/Kolommen. (Een voorbeeld: 1,3,1)",
        },
        {
          name: 'minSizes',
          type: 'array',
          // label: 'Column widths or row heights (separated by commas). Value can be a number (which will be set as flex values/verhoudingswaarden) or a px/% value',
          label:
            "initiele grootte, gescheiden door comma's. Waarden kunnen gegeven worden in pixels. (Een voorbeeld: 130px,200px,300px)",
        },
        {
          name: 'cardSizes',
          type: 'array',
          // label: 'Column widths or row heights (separated by commas). Value can be a number (which will be set as flex values/verhoudingswaarden) or a px/% value',
          label:
            'padding Waarden kunnen gegeven worden in pixels, percentage of getallen. Een getal is verhoudingsgetal ten opzichte van de andere Rijen/Kolommen. (Een voorbeeld: 130px,1,10%)',
        },
        {
          name: 'areas',
          type: 'json',
          // label: 'Column widths or row heights (separated by commas). Value can be a number (which will be set as flex values/verhoudingswaarden) or a px/% value',
          label: 'area definitions',
        },
      ],
    }
  }
}
