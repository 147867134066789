import { lazy } from 'react';

export default class TreeD3Descriptor {
 

  static getDescription() {
    return {
      name: 'TreeD3',
      component: lazy(() =>  import('./TreeD3')), 
      label: 'TreeD3 widget',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: 'to fill tree',
        },
        {
          name: 'publishVariableOnTreeItemClick',
          type: 'text',
          label:
            'Publish/subscribe variabele in which tree item URI is published, when tree item is clicked.',
        },
        {
          name: 'subscribeVariableForTreeItemSelection',
          type: 'text',
          label:
            'Publish/subscribe variabele on which to listen to define tree item selection.',
        },
        {
          name: 'subscribeVariableForTreeConnectionHighlight',
          type: 'text',
          label:
            'Publish/subscribe variabele on which to listen to define which tree connections to highlight',
        },
        {
          name: 'subscribeVariableForFilter',
          type: 'text',
          label:
            'Publish/subscribe variabele on which to listen to define which tree items should be filtered out',
        },
        {
          name: 'rootLabel',
          type: 'text',
          label: 'Label of the root of the tree. Leave empty for no root.',
        },
        {
          name: 'treeType',
          type: 'select',
          label: 'Soort boom',
          options: [
            {
              label: 'Horizontaal',
              value: 'horizontal',
            },
            {
              label: 'Radiaal',
              value: 'radial',
            },
          ],
        },
      ],
    }
  }

  
}

