import React from 'react'

class BBKGApiVizWidget extends React.Component {
  state = {
    value: '',
  }

  render() {
    console.log('rendering apiviz')
    return null
  }

  createVizJSON() {
    var ar = []

    console.log('created viz json', ar)

    return ar
  }
  test() {
    this.createVizJSON()
    this.loadData('select * where {?a ?b ?c} limit 10')
  }
}

export default BBKGApiVizWidget

/*[
  {
    "id": "obj-persoon",
    "name": "Persoon",
    "path": "/personen",
    "input": "persoonsnummer",
    "rx": 100,
    "ry": 100,
    "x": 300,
    "y": 200,
    "color": "pink",
    "connections": [],
    "endpoints": []
  },
  {
    "id": "obj-maatschappelijk-activiteit",
    "name": "Maatschappelijke activiteit",
    "path": "/maatschappelijkeactiviteiten",
    "input": "kvknummer",
    "rx": 100,
    "ry": 100,
    "x": 301,
    "y": 600,
    "color": "lightblue",
    "connections": [
      {
        "connId": "obj-vestiging",
        "endpoints": [
          {
            "name": "Hoofdvestiging",
            "path": "/hoofdvestiging",
            "description": "Levert de hoofdvestiging die hoort bij de maatschappelijke activiteit met het opgegeven KVK-nummer",
            "response": {
              "vestiging": {
                "vestigingsnummer": "string"
              }
            }
          },
          {
            "name": "Vestigingen",
            "path": "/vestigingen",
            "description": "Levert de vestigingen die horen bij de maatschappelijke activiteit met het opgegeven KVK-nummer",
            "response": [
              {
                "vestiging": {
                  "vestigingsnummer": "string"
                }
              }
            ]
          }
        ]
      },
      {
        "connId": "obj-persoon",
        "endpoints": [
          {
            "name": "Eigenaar",
            "path": "/eigenaar"
          }
        ]
      }
    ],
    "endpoints": [
      {
        "name": "Bezoeklocatie",
        "path": "/bezoeklocatie",
        "description": "Levert de afgeleide bezoeklocatie van de maatschappelijke activiteit met het opgegeven KVK-nummer",
        "response": {
          "registratietijdstipOpvoer": "2019-09-27T11:57:00.176Z",
          "registratietijdstipAfvoer": "2019-09-27T11:57:00.176Z",
          "datumAanvang": "string",
          "datumEinde": "string",
          "binnenlandseLocatie": {
            "registratietijdstipOpvoer": "2019-09-27T11:57:00.176Z",
            "registratietijdstipAfvoer": "2019-09-27T11:57:00.176Z",
            "locatienummer": "string",
            "bagLocatieIdentificatie": "string",
            "binnenlandsAdres": {
              "registratietijdstipOpvoer": "2019-09-27T11:57:00.176Z",
              "registratietijdstipAfvoer": "2019-09-27T11:57:00.176Z",
              "bagAdresIdentificatie": "string",
              "straatnaam": "string",
              "huisnummer": 0,
              "huisletter": "string",
              "huisnummertoevoeging": "string",
              "postcode": "string",
              "plaats": "string"
            },
            "aanduidingBijBinnenlandsAdres": {
              "registratietijdstipOpvoer": "2019-09-27T11:57:00.176Z",
              "registratietijdstipAfvoer": "2019-09-27T11:57:00.176Z",
              "aanduiding": "Bij",
              "referentielocatie": {
                "registratietijdstipOpvoer": "2019-09-27T11:57:00.176Z",
                "registratietijdstipAfvoer": "2019-09-27T11:57:00.176Z",
                "locatienummer": "string",
                "bagLocatieIdentificatie": "string",
                "binnenlandsAdres": {
                  "registratietijdstipOpvoer": "2019-09-27T11:57:00.176Z",
                  "registratietijdstipAfvoer": "2019-09-27T11:57:00.176Z",
                  "bagAdresIdentificatie": "string",
                  "straatnaam": "string",
                  "huisnummer": 0,
                  "huisletter": "string",
                  "huisnummertoevoeging": "string",
                  "postcode": "string",
                  "plaats": "string"
                }
              }
            },
            "vrijeLocatie": {
              "registratietijdstipOpvoer": "2019-09-27T11:57:00.176Z",
              "registratietijdstipAfvoer": "2019-09-27T11:57:00.176Z",
              "locatiebeschrijving": "string"
            }
          },
          "buitenlandseLocatie": {
            "registratietijdstipOpvoer": "2019-09-27T11:57:00.176Z",
            "registratietijdstipAfvoer": "2019-09-27T11:57:00.176Z",
            "locatienummer": "string",
            "adres": {
              "registratietijdstipOpvoer": "2019-09-27T11:57:00.176Z",
              "registratietijdstipAfvoer": "2019-09-27T11:57:00.176Z",
              "adresregel1": "string",
              "adresregel2": "string",
              "adresregel3": "string",
              "land": {
                "naam": "string",
                "landCode": 0,
                "toegangsnummer": "string"
              }
            }
          },
          "toevoeging": "string",
          "afgeschermd": "ja"
        }
      },
      {
        "name": "Postlocatie",
        "path": "/postlocatie",
        "description": "Levert de afgeleide postlocatie van de maatschappelijke activiteit met het opgegeven KVK-nummer",
        "response": {
          "registratietijdstipOpvoer": "2019-09-27T11:57:49.585Z",
          "registratietijdstipAfvoer": "2019-09-27T11:57:49.585Z",
          "datumAanvang": "string",
          "datumEinde": "string",
          "binnenlandseLocatie": {
            "registratietijdstipOpvoer": "2019-09-27T11:57:49.585Z",
            "registratietijdstipAfvoer": "2019-09-27T11:57:49.585Z",
            "locatienummer": "string",
            "bagLocatieIdentificatie": "string",
            "binnenlandsAdres": {
              "registratietijdstipOpvoer": "2019-09-27T11:57:49.585Z",
              "registratietijdstipAfvoer": "2019-09-27T11:57:49.585Z",
              "bagAdresIdentificatie": "string",
              "straatnaam": "string",
              "huisnummer": 0,
              "huisletter": "string",
              "huisnummertoevoeging": "string",
              "postcode": "string",
              "plaats": "string"
            },
            "aanduidingBijBinnenlandsAdres": {
              "registratietijdstipOpvoer": "2019-09-27T11:57:49.585Z",
              "registratietijdstipAfvoer": "2019-09-27T11:57:49.585Z",
              "aanduiding": "Bij",
              "referentielocatie": {
                "registratietijdstipOpvoer": "2019-09-27T11:57:49.585Z",
                "registratietijdstipAfvoer": "2019-09-27T11:57:49.585Z",
                "locatienummer": "string",
                "bagLocatieIdentificatie": "string",
                "binnenlandsAdres": {
                  "registratietijdstipOpvoer": "2019-09-27T11:57:49.585Z",
                  "registratietijdstipAfvoer": "2019-09-27T11:57:49.585Z",
                  "bagAdresIdentificatie": "string",
                  "straatnaam": "string",
                  "huisnummer": 0,
                  "huisletter": "string",
                  "huisnummertoevoeging": "string",
                  "postcode": "string",
                  "plaats": "string"
                }
              }
            },
            "vrijeLocatie": {
              "registratietijdstipOpvoer": "2019-09-27T11:57:49.585Z",
              "registratietijdstipAfvoer": "2019-09-27T11:57:49.585Z",
              "locatiebeschrijving": "string"
            }
          },
          "buitenlandseLocatie": {
            "registratietijdstipOpvoer": "2019-09-27T11:57:49.585Z",
            "registratietijdstipAfvoer": "2019-09-27T11:57:49.585Z",
            "locatienummer": "string",
            "adres": {
              "registratietijdstipOpvoer": "2019-09-27T11:57:49.585Z",
              "registratietijdstipAfvoer": "2019-09-27T11:57:49.585Z",
              "adresregel1": "string",
              "adresregel2": "string",
              "adresregel3": "string",
              "land": {
                "naam": "string",
                "landCode": 0,
                "toegangsnummer": "string"
              }
            }
          },
          "binnenlandsePostlocatie": {
            "registratietijdstipOpvoer": "2019-09-27T11:57:49.586Z",
            "registratietijdstipAfvoer": "2019-09-27T11:57:49.586Z",
            "locatienummer": "string",
            "adres": {
              "registratietijdstipOpvoer": "2019-09-27T11:57:49.586Z",
              "registratietijdstipAfvoer": "2019-09-27T11:57:49.586Z",
              "postadrestype": "Postbus",
              "nummer": 0,
              "postcode": "string",
              "plaats": "string"
            }
          },
          "toevoegingBezoeklocatie": "string"
        }
      }
    ],
    "response": {
      "kvkNummer": "string",
      "gegevensgebruik": {
        "directMailing": "WelDirectMailing"
      }
    },
    "description": "Levert de (basis)gegevens van de maatschappelijke activiteit met het opgegeven KVK-nummer"
  },
  {
    "id": "obj-vestiging",
    "name": "Vestiging",
    "path": "/vestigingen",
    "input": "vestigingsnummer",
    "rx": 100,
    "ry": 100,
    "x": 700,
    "y": 700,
    "color": "lightgreen",
    "connections": [
      {
        "connId": "obj-persoon",
        "endpoints": [
          {
            "name": "Eigenaar",
            "path": "/eigenaar"
          }
        ]
      }
    ]
  }
]
*/
