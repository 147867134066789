import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"

export function SortableItem(props: {
  id: string
  className?: string
  children: React.ReactNode
}) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={props.className}
      style={{
        transform: CSS.Translate.toString(transform),
        transition: transition
      }}
    >
      {props.children}
    </div>
  );
}
