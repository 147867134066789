
import { lazy } from 'react';


export default class BBLibremapDescriptor {



static getDescription() {
    return {
      name: 'BB Maplibre',
      component: lazy(() =>  import('./BBLibremap')),
      label: 'BBLibremap',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'Query to fill tree. First item should be the object label, the second the object URI, the third the parent URI. optional you can use icon, icon2 and icon3 variables for fa-fa icons ()',
        },
       
      ],
    }
  }

}