import { lazy } from 'react';

export const jobStatusWidgetDescription = {
    name: 'JobStatus',
    component: lazy(() => import('./JobStatus' )),
    label: 'Job status indicator (rule engine)',
    variables: [
      {
        name: 'initiatorTagsToInclude',
        type: 'text',
        label:
          'Monitor only jobs initiated by specified rule tags. Can be comma separated. By default all jobs are monitored.'
      },
      {
        name: 'statusLabels',
        type: 'json',
        label:
          'JSON that maps the job statuses to a human-friendly label'
      },
      {
        name: 'statusTextTemplateString',
        type: 'text',
        label:
          'Template string for the job status display. May have HTML. You can use placeholders {{.count}} and {{.status}} (NOTE: these are not available as pubsub variables)'
      },
      {
        name: 'zeroJobsString',
        type: 'text',
        label:
          'Text for the job status display when there are zero active jobs. May have HTML.'
      },
      {
        name: 'refetchInterval',
        type: 'text',
        label: 'How often to refresh in seconds. Cannot be lower than 1.'
      }
    ],
  }
