import './bbutil'
import BBIFrame from './BBIFrame'

class BBYasr extends BBIFrame {
  getUrl() {
    BBIFrame.scrolling = 'no'
    return `/${window.bbFolderName}/bbyasr.html`
  }

  static getDescription() {
    return {
      name: 'BBYasr',
      component: BBYasr,
      label: 'BB Yasr',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: 'to fill yasr chart. ',
        },
        {
          name: 'title',
          type: 'text',
          label: 'title of the chart ',
        },
        {
          name: 'chartType',
          type: 'select',
          label: 'type of the chart',
          options: [
            {
              label: 'Pie chart',
              value: 'PieChart',
            },
            {
              label: 'ColumnChart',
              value: 'ColumnChart',
            },
            {
              label: 'Bar chart',
              value: 'BarChart',
            },
            {
              label: 'Line chart',
              value: 'LineChart',
            },
            {
              label: 'Donut (nog niet aanwezig)',
              value: 'Donut',
            },
            {
              label: 'Gauge',
              value: 'Gauge',
            },
          ],
        },
        {
          name: 'ddd',
          type: 'boolean',
          label: '3D',
        },
        {
          name: 'height',
          type: 'text',
          label: 'height',
        },
      ],
    }
  }
}

export default BBYasr
