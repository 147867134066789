import './bbutil'
import BBIFrame from './BBIFrame'

class BBTreeTable extends BBIFrame {
  getUrl = () => `/${window.bbFolderName}/w2ui/treetable.html`

  constructor() {
    super()
    // console.log("BBTREE TABLE Constructor");
    this.scrolling = 'no'
     this.noEvents=false;
     this.usesParameters=false;
  }

  static getDescription() {
    return {
      name: 'BBTreeTable',
      component: BBTreeTable,
      label: 'BB Tree Table widget',
      variables: [
        {
          name: 'query',
          defaultQuery:"PREFIX owl: <http://www.w3.org/2002/07/owl#>\n\rPREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n\r\n\rselect distinct ?text ?uri ?parent \n\rwhere\n\r{\n\r \n\r    graph <{{graph}}>\n\r    {\n\r       ?uri a owl:Class.\n\r        ?uri rdfs:label ?text.\n\r    optional {?uri rdfs:subClassOf ?parent. } \n\r   \n\r  \n\r    }\n\r}\n\r\n\rlimit 1000",
          type: 'yasgui',
          label: 'user ?text ?uri ?parent ?icon ',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which an item URI is published, when a feature in the tree is clicked.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },
        {
          name: 'publishVariable1',
          type: 'text',
          label:
            'Variabele in which an item var1 is published (if available), when a feature in the tree is clicked.',
        },
        {
          name: 'publishTextVariable',
          type: 'text',
          label:
            'Variabele in which the label is published , when a feature in the tree is clicked.',
        },
        {
          name: 'title',
          type: 'text',
          label: 'title',
        },
        {
          name: 'createRoot',
          type: 'boolean',
          label: 'createRoot',
        },
        {
          name: 'expandLevel',
          type: 'int',
          label: 'expandLevel',
        },
        {
          name: 'icon',
          type: 'text',
          label: 'icon',
        },
        
        {
          name: 'extraCSS',
          type: 'text',
          label: 'extra css',
        },
        {
          name: 'noInfoIcon',
          type: 'boolean',
          label: 'no info icon',
        },

        {
          name: 'groupByPropertyValue',
          type: 'text',
          label: 'group by propertyvalue',
        },

        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
      ],
    }
  }

  shouldComponentUpdate222(arg, arg2) {
    var update = false

    if (this.iface == null) {
      return true
    }

    if (this.props.subscribeVariable != null) {
      var selected = this.props.pubsub[this.props.subscribeVariable]
      if (this.selected !== selected) {
        this.iface.selectUri(selected)
        this.selected = selected
        return false
      }
    }

    /*
    

    try{
      //console.log("map receives an should update ",arg.data,this.iface.me.iface.me.props.data);
      if (arg.data!=this.iface.me.iface.me.props.data)
      {
        console.log("nieuw data ? update component",arg.data);
        update= true;
        return true;
      }
    }
    catch(e){}


    var sub = arg.subscribeVariable;
    if (sub!=null)
    {
     // console.log("map can be updates by exertal variable "+sub);
      var selection =arg.pubsub[sub];
      
      if (selection!=null)
      {
       // console.log("map found externalvaue"+selection,this.state.selectedUri); 
        if (this.state.selectedUri!=selection)
        {
          //console.log("map receives an external selection",this.iface);
          
               this.iface.selectUri(selection);
              // this.setState({selectedUri:selection});
        }

       
      }
    }
    */

    return update
  }
}

export default BBTreeTable
