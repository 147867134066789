type LayoutName = keyof typeof LAYOUT_NAME
type Config = { showFooter: boolean }
type LayoutConfig = { [K in LayoutName]: Config }

export const LAYOUT_NAME = {
  CUSTOM: 'CUSTOM',
  ORIGINAL: 'ORIGINAL',
} as const

const LOAD_STYLE = {
  CUSTOM: 'overlay',
  ORIGINAL: 'classic',
} as const

const LAYOUT_CONFIG: LayoutConfig = {
  CUSTOM: { showFooter: false },
  ORIGINAL: { showFooter: true },
}

export function inflateLayout<V extends { layoutName: LayoutName }>(viewer: V) {
  const layoutName = viewer.layoutName || LAYOUT_NAME.ORIGINAL
  return { ...viewer, layoutName, layout: LAYOUT_CONFIG[layoutName] }
}

export function getLoadStyle(layoutName: LayoutName = LAYOUT_NAME.ORIGINAL) {
  return LOAD_STYLE[layoutName]
}
