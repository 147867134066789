export default function handleEvent(state = {}, action) {
  switch (action.type) {
    case 'ITEM_SELECT_EVENT':
      return {
        ...state,
        selectedItem: action.payload,
      }
    default:
      return state
  }
}
