import React, { Component } from 'react'
import styles from './Yasgui.module.css'
//import yasqe from 'yasgui-yasqe'
import yasgui from '@triply/yasgui'
import '../../../node_modules/@triply/yasqe/build/yasqe.min.css'
//import '../../../node_modules/yasgui-yasqe/dist/yasqe.css'
import axios from 'axios'
import { Button } from 'reactstrap'
import { restructureResponse } from '../../helpers/sparql'

class Yasgui extends Component {
  constructor(props) {
    super(props)
    this.textArea = React.createRef()
  }

  state = {
    message: '',
  }

  // componentDidMount() {
  //   setTimeout(() => {
  //     this.yas = yasqe.fromTextArea(this.textArea.current);
  //     // this.yas.setSize('100%')
  //     let defaultValue = `PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
  //     select * where {
  //       ?s ?p ?o .
  //     } limit 100`;
  //     this.yas.setValue(defaultValue);
  //     // debugger
  //     // this.textArea.current.focus()
  //   }, 500);

  //   // this.yas.on('blur', () => {
  //   //   // this.props.handleBlur(this.yas.getValue());
  //   //   this.loadData(this.yas.getValue())
  //   // });
  // }

  componentDidUpdate(prevProps) {
    if (
      prevProps.withinSelectedTab !== this.props.withinSelectedTab &&
      this.props.withinSelectedTab
    ) {
      if (this.yas) return
      this.yas = new yasgui.Yasqe(this.textArea.current);

     // this.yas = yasgui.Yasqe.fromTextArea(this.textArea.current)
      let defaultValue = `PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
select * where { 
  ?s ?p ?o .
} limit 2`
      this.yas.setValue(defaultValue)
    }
  }

  onRunClick = () => {
    this.loadData(this.yas.getValue())
  }

  loadData(query) {
    if (!this.props.repoURL) return console.log('Repo URL is not set!')

    if (query) {
      // default use proxy server
      let url = '/api/sparql/' + this.props.repoURL
      let data = {
        query: encodeURIComponent(query),
      }
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('kvk-vb-token')}`,
      }
      if (this.props.repoURL.startsWith('http')) {
        // request sparql endpoint directly
        url = this.props.repoURL
        data =
          'infer=true&limit=1000&offset=0&sameAs=true&query=' +
          encodeURIComponent(query)
        headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/sparql-results+json',
        }
      }

      axios({
        method: 'post',
        url,
        data,
        headers,
      })
        .then((response) => {
          this.setState({
            data: response.data,
            message: '',
          })
        })
        .catch((err) => {
          console.log(err)
          this.setState({
            message: 'Er ging iets mis met laden van de data.',
          })
        })
    }
  }

  renderData() {
    if (this.state.message) {
      return <div>{this.state.message}</div>
    }

    if (!this.state.data) return null
    let headVars = this.state.data.head.vars
    let items = restructureResponse(this.state.data)

    return (
      <table>
        <thead>
          <tr>
            {headVars.map((headVar, index) => (
              <th key={index}>{headVar}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            return (
              <tr key={index}>
                {headVars.map((headVar, index) => {
                  let value = item[headVar] || ''
                  return <td key={index}>{value}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  render() {
    return (
      <div className={styles.main}>
       <div ref={this.textArea} type="textarea" />
        <div className={styles.buttonContainer}>
          <Button
            color="primary"
            style={{ minWidth: '120px', marginTop: '12px' }}
            onClick={this.onRunClick}
          >
            RUN
          </Button>
        </div>
        <div className={styles.tableContainer}>{this.renderData()}</div>
      </div>
    )
  }
}

export default Yasgui
