import { z } from "zod"

export const ProjectSchema = z.object({
  id: z.string(),
  title: z.string(),
  path: z.string(),
  createdAt: z.string()
})

export type Project = z.infer<typeof ProjectSchema>
