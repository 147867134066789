import axios from 'axios'

class LoginHelper {

  static async logoff(repoUrl = window.top.url, rv) {
    try {
      axios({
        method: 'post',
        url: repoUrl.replace('sparql', 'logout'),
        withCredentials: true,
      })
      if (rv!=null) rv.call(this);
    } catch(e) {
      console.log("error logoff",e);
    }
  }

}

window.top.logoff = LoginHelper.logoff
export default LoginHelper
