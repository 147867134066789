import './bbutil'
import BBIFrame from './BBIFrame'

class BBIfcViewerNextGen extends BBIFrame {
  getUrl = () => `/${window.bbFolderName}/static/iframes/ifc-viewer/index.html`

  constructor() {
    super()

    this.scrolling = 'yes'
    this.noEvents = false
    this.usesParameters=false;
  }

  static getDescription() {
    return {
      name: 'BBIfcViewerNextGen',
      component: BBIfcViewerNextGen,
      label: 'BB IFC Viewer next gen',
      variables: [

      ],
    }
  }
}

export default BBIfcViewerNextGen
