import { lazy } from 'react';

export default class BBSparnaturalDescriptor{


static getDescription() {
    return {
      name: 'BBSparnatural',
      component: lazy(() =>  import('./BBSparnatural')),
      label: 'BB Sparnatural',
      variables: [
       
          
          {
            name: 'configttl',
            type: 'text',
            label: 'local filename such as \"config.ttl\". (refresh is necessary) ',
          },
          {
            name: 'lang',
            type: 'text',
            label: 'language, default is nl (refresh is necessary)',
          },
          {
            name: 'limit',
            type: 'text',
            label: 'limit (default is 100000) (refresh is necessary)',
          },

          {
            name: 'configttlAsString',
            type: 'text',
            label: 'config as a string. (not implemented) ',
          },


        {
          name: 'query',
          type: 'yasgui',
          label: 'perhaps create sparnatural config using sparql results? (not implemented) ',
        }
    ]
}
}
}
