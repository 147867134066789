import React from "react"
import styles from "./WidgetWrapper.module.css"
import clsx from "clsx"

type Props = {
  userActionsDisabled: boolean
  clickable: boolean
  children: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

export function WidgetWrapper(props: Props) {
  const { userActionsDisabled, clickable, ...otherProps } = props
  return (
    <div className={clsx(
      props.className,
      styles.wrapper, {
        [styles.clickable]: clickable && !userActionsDisabled
      })}
         data-user-actions-disabled={userActionsDisabled}
         {...otherProps}
      aria-disabled={userActionsDisabled}
      data-testid="widget-wrapper"
    >
      {props.children}
    </div>
  )
}
