import { Component } from 'react'
//import {jqeury,$} from 'jquery'

//import svgIcons from 'trumbowyg/dist/ui/icons.svg'
//const trumbowygIconsId = 'trumbowyg-icons'

//require ('trumbowyg/dist/trumbowyg');

class BBNotebook extends Component {
  constructor(props) { //eslint-disable-line no-useless-constructor
    super(props)
  }
  /*
  componentWillMount() {

  }

  componentDidMount() {

    const {
      id,
      lang,
      buttons,
      semantic,
      resetCss,
      removeformatPasted,
      autogrow,
      data,
      disabled,
      onFocus,
      onBlur,
      onInit,
      onChange,
      onResize,
      onPaste,
      onOpenFullScreen,
      onCloseFullScreen,
      onClose,
      shouldUseSvgIcons,
      shouldInjectSvgIcons,
      svgIconsPath,
      btnsDef,
      plugins
    } = this.props

    if (shouldInjectSvgIcons && $(`#${trumbowygIconsId}`).length === 0) {
      $('body').prepend(`<div id="${trumbowygIconsId}">${svgIcons}</div>`)
    }

    const trumbowygInstance = $(`#${id}`)
      .trumbowyg({
        lang: lang,
        btns: buttons,
        btnsDef: btnsDef,
        semantic: semantic,
        resetCss: resetCss,
        removeformatPasted: removeformatPasted,
        autogrow: autogrow,
        plugins: plugins,
        svgPath: shouldUseSvgIcons
          ? shouldInjectSvgIcons ? '' : svgIconsPath
          : false
      })

    if (onFocus) {
      trumbowygInstance.on('tbwfocus', onFocus)
    }

    if (onBlur) {
      trumbowygInstance.on('tbwblur', onBlur)
    }

    if (onInit) {
      trumbowygInstance.on('tbwinit', onInit)
    }

    if (onChange) {
      trumbowygInstance.on('tbwchange', onChange)
    }

    if (onResize) {
      trumbowygInstance.on('tbwresize', onResize)
    }

    if (onPaste) {
      trumbowygInstance.on('tbwpaste', onPaste)
    }

    if (onOpenFullScreen) {
      trumbowygInstance.on('tbwopenfullscreen', onOpenFullScreen)
    }

    if (onCloseFullScreen) {
      trumbowygInstance.on('tbwclosefullscreen', onCloseFullScreen)
    }

    if (onClose) {
      trumbowygInstance.on('tbwclose', onClose)
    }

    $(`#${id}`).trumbowyg('html', data)
    $(`#${id}`).trumbowyg(disabled === true ? 'disable' : 'enable')
  }

  componentWillReceiveProps(nextProps) {
  }

  shouldComponentUpdate(nextProps, nextState) {

    return nextProps.data !== this.props.data
      || nextProps.disabled !== this.props.disabled
  }

  componentWillUpdate(nextProps, nextState) {

  }

  componentDidUpdate(prevProps, prevState) {
    $(`#${this.props.id}`).trumbowyg('html', this.props.data)
    $(`#${this.props.id}`).trumbowyg(this.props.disabled === true ? 'disable' : 'enable')
  }

  componentWillUnmount() {
    $(`#${this.props.id}`).trumbowyg('destroy')
  }

  render() {
    return (
      <div id={`${this.props.id}`} placeholder={this.props.placeholder}></div>
    )
  }


defaultProps = {
  buttons: [
    ['viewHTML'],
    ['formatting'],
    'btnGrp-semantic',
    ['superscript', 'subscript'],
    ['link'],
    ['insertImage'],
    'btnGrp-justify',
    'btnGrp-lists',
    ['horizontalRule'],
    ['removeformat'],
    ['fullscreen']
  ],
  semantic: true,
  resetCss: false,
  removeformatPasted: false,
  autogrow: false,
  disabled: false,
  btnsDef: {},
  shouldUseSvgIcons: true,
  shouldInjectSvgIcons: true,
  plugins: {}
};


*/
}

export default BBNotebook
