import * as React from "react"
import { createTheme, alpha } from "@mui/material/styles"
import { grey } from "@mui/material/colors"

/*
 * Custom theme that adds a 'grey' color variant for buttons,
 * this looks the same as the buttons in MUI v4 when the color property was not set.
 * See: https://mui.com/material-ui/migration/v5-component-changes/#%E2%9C%85-remove-default-color-prop
 */

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
}

declare module "@mui/material" {
  interface Color {
    main: string;
    dark: string;
  }
}

const greyPaletteTheme = createTheme({
  palette: {
    grey: {
      main: grey[300],
      dark: grey[400]
    }
  }
})

export const theme = createTheme(greyPaletteTheme, {
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", color: "grey" },
          style: {
            color: greyPaletteTheme.palette.getContrastText(greyPaletteTheme.palette.grey[300])
          }
        },
        {
          props: { variant: "outlined", color: "grey" },
          style: {
            color: greyPaletteTheme.palette.text.primary,
            borderColor:
              greyPaletteTheme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.23)"
                : "rgba(255, 255, 255, 0.23)",
            "&.Mui-disabled": {
              border: `1px solid ${greyPaletteTheme.palette.action.disabledBackground}`
            },
            "&:hover": {
              borderColor:
                greyPaletteTheme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.23)"
                  : "rgba(255, 255, 255, 0.23)",
              backgroundColor: alpha(
                greyPaletteTheme.palette.text.primary,
                greyPaletteTheme.palette.action.hoverOpacity
              )
            }
          }
        },
        {
          props: { color: "grey", variant: "text" },
          style: {
            color: greyPaletteTheme.palette.text.primary,
            "&:hover": {
              backgroundColor: alpha(
                greyPaletteTheme.palette.text.primary,
                greyPaletteTheme.palette.action.hoverOpacity
              )
            }
          }
        }
      ]
    }
  }
})
