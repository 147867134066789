import { lazy } from 'react'
import { sideMenuConfigs } from './BBSideMenuConfigs'

export default class BBSideMenuDescriptor {

  static getDescription() {
    return {
      name: 'BBSideMenu',
      component: lazy(() =>  import('./BBSideMenu')),
      label: 'BBSideMenu',
      variables: sideMenuConfigs,
    }
  }

}