import ReactDOM from "react-dom"

const getRoot = () => {
  let element = document.getElementById('modals');
  if (!element) {
    element = document.createElement('div');
    element.setAttribute('id', 'modals');
    document.body.appendChild(element);
  }
  return element;
};

interface ShowableComponentProps<TResult> {
  onClose: (result: TResult) => void;
}

export type ShowableComponent<TProps, TResult> = (props: ShowableComponentProps<TResult> & TProps) => JSX.Element;

const show = <TProps, TResult>(
  Component: ShowableComponent<TProps, TResult>,
  props?: Omit<TProps, "onClose">
) => {
  const root = getRoot();
  const container = document.createElement('div');
  root.appendChild(container);

  const cleanup = () => {
    // @ts-ignore Render null is a valid use-case
    ReactDOM.render(null, container);
    container.remove();
  };

  return new Promise<TResult | undefined>((resolve) => {
    const closeHandler = (result: TResult) => {
      resolve(result);
      cleanup();
    };

    ReactDOM.render(
      /* @ts-ignore Unclear what the problem is here */
      <Component {...props} onClose={closeHandler} />,
      container
    );
  });
};

export const useDialog = () => {
  return <TProps, TResult>(
    Component: ShowableComponent<TProps, TResult>,
    props?: Omit<TProps, "onClose">
  ) => show(Component, props);
};
